// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';
import logoImage from '../assets/image.png'; // Ensure this path is correct

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavigation = (e) => {
    closeMobileMenu();
    
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  // Prevent background scroll when mobile menu is open
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobileMenuOpen]);

  return (
    <nav className={`fixed top-0 left-0 w-full z-50 bg-white border-b border-gray-100 shadow-sm
                    transition-all duration-300 ease-in-out
                    ${isScrolled ? 'shadow-md' : ''}`}>
      {/* Main navbar container */}
      <div className="relative flex items-center justify-between px-3 sm:px-4 md:px-6 lg:px-8 xl:px-20 py-2 sm:py-3 md:py-4">
        {/* Logo */}
        <Link 
          to="/" 
          onClick={handleNavigation} 
          className="flex items-center text-gray-900 transition-all duration-300"
        >
          <img 
            src={logoImage} 
            alt="Trussed.ai" 
            className="h-4 sm:h-5 md:h-6 lg:h-7 xl:h-5 flex-shrink-0"
          />
        </Link>

        {/* Mobile Menu Icon */}
        <div className="lg:hidden text-xl sm:text-2xl cursor-pointer text-gray-900" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>

        {/* Desktop Menu */}
        <ul className="hidden lg:flex items-center gap-4 xl:gap-8">
          {/* Home Link - With underline */}
          <li>
            <Link 
              to="/" 
              onClick={handleNavigation} 
              className="text-sm xl:text-base
                       text-gray-900 font-medium
                       leading-tight sm:leading-normal
                       tracking-tight
                       relative z-20
                       transition-all duration-300 ease-in-out
                       hover:text-blue-600
                       after:content-['']
                       after:absolute
                       after:w-0
                       after:h-[2px]
                       after:bg-blue-600
                       after:left-0
                       after:-bottom-1
                       after:rounded-full
                       after:transition-all
                       after:duration-300
                       hover:after:w-full"
            >
              Home
            </Link>
          </li>

          {/* Solutions Dropdown - No underline */}
          <li className="relative group">
            <div
              className="flex items-center text-sm xl:text-base
                       text-gray-900 font-medium
                       leading-tight sm:leading-normal
                       tracking-tight
                       relative z-20
                       cursor-pointer
                       transition-all duration-300 ease-in-out
                       hover:text-blue-600"
            >
              Solutions <FaChevronDown className="ml-1 text-xs xl:text-sm text-gray-900 group-hover:text-blue-600 transition-transform duration-300 group-hover:rotate-180" />
            </div>
            {/* Dropdown Menu */}
            <ul className="absolute left-0 mt-2 bg-white rounded-lg shadow-lg 
                          opacity-0 invisible group-hover:opacity-100 group-hover:visible
                          transform translate-y-2 group-hover:translate-y-0
                          transition-all duration-300 ease-in-out
                          min-w-[150px] border border-gray-200">
              <li>
                <Link 
                  to="/solutions/enterprise" 
                  onClick={handleNavigation} 
                  className="block px-4 py-2 text-sm text-gray-900 font-medium
                           relative
                           transition-all duration-300 ease-in-out
                           hover:text-blue-600 hover:bg-blue-50
                           whitespace-nowrap"
                >
                  Enterprise
                </Link>
              </li>
              <li>
                <Link 
                  to="/solutions/university" 
                  onClick={handleNavigation} 
                  className="block px-4 py-2 text-sm text-gray-900 font-medium
                           relative
                           transition-all duration-300 ease-in-out
                           hover:text-blue-600 hover:bg-blue-50
                           whitespace-nowrap"
                >
                  University
                </Link>
              </li>
            </ul>
          </li>
          {/* Company Dropdown - No underline */}
          <li className="relative group">
            <div
              className="flex items-center text-sm xl:text-base
                       text-gray-900 font-medium
                       leading-tight sm:leading-normal
                       tracking-tight
                       relative z-20
                       cursor-pointer
                       transition-all duration-300 ease-in-out
                       hover:text-blue-600"
            >
              Company <FaChevronDown className="ml-1 text-xs xl:text-sm text-gray-900 group-hover:text-blue-600 transition-transform duration-300 group-hover:rotate-180" />
            </div>
            <ul className="absolute left-0 mt-2 bg-white rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 min-w-[150px] border border-gray-200">
              <li>
                <Link 
                  to="/company/about" 
                  onClick={handleNavigation} 
                  className="block px-4 py-2 text-sm text-gray-900 font-medium
                           relative
                           transition-all duration-300 ease-in-out
                           hover:text-blue-600 hover:bg-blue-50
                           whitespace-nowrap"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link 
                  to="/company/contact" 
                  onClick={handleNavigation} 
                  className="block px-4 py-2 text-sm text-gray-900 font-medium
                           relative
                           transition-all duration-300 ease-in-out
                           hover:text-blue-600 hover:bg-blue-50
                           whitespace-nowrap"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </li>
          {/* Blog Link - With underline */}
          <li>
            <Link 
              to="/blog" 
              onClick={handleNavigation} 
              className="text-sm xl:text-base
                       text-gray-900 font-medium
                       leading-tight sm:leading-normal
                       tracking-tight
                       relative z-20
                       transition-all duration-300 ease-in-out
                       hover:text-blue-600
                       after:content-['']
                       after:absolute
                       after:w-0
                       after:h-[2px]
                       after:bg-blue-600
                       after:left-0
                       after:-bottom-1
                       after:rounded-full
                       after:transition-all
                       after:duration-300
                       hover:after:w-full"
            >
              Blog
            </Link>
          </li>
        </ul>

        {/* Desktop "Book Demo" Button - updated background */}
        <div className="hidden lg:flex">
          <Link
            to="/book-demo"
            className="px-4 sm:px-5 md:px-6 py-2 
                     bg-white text-blue-600 
                     border-2 border-blue-600
                     rounded-md
                     font-medium
                     hover:bg-blue-600 hover:text-white
                     transition-all duration-300 
                     text-sm xl:text-base 
                     min-w-[120px] sm:min-w-[130px] md:min-w-[140px] 
                     text-center"
          >
            Book Demo
          </Link>
        </div>
      </div>

      {/* Mobile Menu - updated background to be solid */}
      {isMobileMenuOpen && (
        <ul className="lg:hidden absolute top-full left-0 w-full flex flex-col gap-3 sm:gap-4 px-3 sm:px-4 py-4 sm:py-6 bg-white shadow-md">
          {/* Mobile menu items with responsive text */}
          <li>
            <Link 
              to="/" 
              onClick={handleNavigation} 
              className="text-sm sm:text-base font-medium
                       bg-gradient-to-r from-[#5195f5] to-[#5195f5]
                       bg-clip-text text-transparent 
                       leading-tight sm:leading-normal
                       tracking-tight
                       relative z-20
                       [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]
                       hover:opacity-80 transition-opacity duration-200"
            >
              Home
            </Link>
          </li>
          {/* Solutions Dropdown for Mobile */}
          <li className="relative">
            <details className="group">
              <summary className="flex items-center justify-between cursor-pointer
                                    text-sm sm:text-base
                                    bg-gradient-to-r from-[#5195f5] to-[#5195f5]
                                    bg-clip-text text-transparent 
                                    leading-tight sm:leading-normal
                                    tracking-tight
                                    relative z-20
                                    [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]
                                    hover:opacity-80 transition-opacity duration-200">
                <span>Solutions</span>
                <FaChevronDown className="ml-1 text-xs sm:text-sm text-[#5195f5]" />
              </summary>
              <ul className="mt-2 bg-white rounded-lg shadow-lg pl-4 border border-gray-100">
                <li>
                  <Link 
                    to="/solutions/enterprise" 
                    onClick={handleNavigation} 
                    className="block px-4 py-2 text-sm text-blue-600 hover:text-blue-600 hover:bg-gray-50 font-medium transition-colors duration-200"
                  >
                    Enterprise
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/solutions/university" 
                    onClick={handleNavigation} 
                    className="block px-4 py-2 text-sm text-blue-600 hover:text-blue-600 hover:bg-gray-50 font-medium transition-colors duration-200"
                  >
                    University
                  </Link>
                </li>
              </ul>
            </details>
          </li>
          {/* Company Dropdown for Mobile */}
          <li className="relative">
            <details className="group">
              <summary className="flex items-center justify-between cursor-pointer
                                    text-sm sm:text-base
                                    bg-gradient-to-r from-[#5195f5] to-[#5195f5]
                                    bg-clip-text text-transparent 
                                    leading-tight sm:leading-normal
                                    tracking-tight
                                    relative z-20
                                    [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]
                                    hover:opacity-80 transition-opacity duration-200">
                <span>Company</span>
                <FaChevronDown className="ml-1 text-xs sm:text-sm text-[#5195f5]" />
              </summary>
              <ul className="mt-2 bg-white rounded-lg shadow-lg pl-4 border border-gray-100">
                <li>
                  <Link 
                    to="/company/about" 
                    onClick={handleNavigation} 
                    className="block px-4 py-2 text-sm text-blue-600 hover:text-blue-600 hover:bg-gray-50 font-medium transition-colors duration-200"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/company/contact" 
                    onClick={handleNavigation} 
                    className="block px-4 py-2 text-sm text-blue-600 hover:text-blue-600 hover:bg-gray-50 font-medium transition-colors duration-200"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </details>
          </li>
          {/* Other mobile menu items */}
          <li>
            <Link 
              to="/blog" 
              onClick={handleNavigation} 
              className="text-blue-600 hover:text-blue-600 font-medium transition-colors duration-200 text-sm sm:text-base"
            >
              Blog
            </Link>
          </li>
          <li>
            <Link 
              to="/support" 
              onClick={handleNavigation} 
              className="text-blue-600 hover:text-blue-600 font-medium transition-colors duration-200 text-sm sm:text-base"
            >
              Support
            </Link>
          </li>
          {/* Mobile "Book Demo" Button */}
          <li>
            <Link
              to="/book-demo"
              className="inline-flex items-center justify-center 
                       px-4 sm:px-6 py-2 
                       bg-white text-blue-600 
                       border-2 border-blue-600
                       rounded-md
                       font-medium
                       hover:bg-blue-600 hover:text-white
                       transition-all duration-300 
                       text-sm sm:text-base 
                       min-w-[120px] sm:min-w-[140px]"
            >
              Book Demo
            </Link>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSpring, animated } from 'react-spring';
import backgroundSvg from '../assets/background.svg';
import erpIcon from '../assets/highlight-entepriseai.jpg';
import rectSvg from '../assets/rect.svg';
import ellipseSvg from '../assets/ellipse.svg';
import { Helmet } from 'react-helmet-async';
import { 
  FaHeadset,
  FaCode,
  FaFlask,
  FaChartLine,
  FaMoneyBillWave,
  FaHandshake,
  FaShieldAlt,
  FaUserLock,
  FaExclamationTriangle,
  FaDollarSign,
  FaDatabase,
  FaUsersCog,
  FaFilter,
  FaChartBar,
  FaChevronLeft,
  FaChevronRight,
  FaServer,
  FaPlug,
  FaRocket,
  FaGraduationCap,
  FaFileContract,
  FaBolt,
  FaLinkedinIn,
  FaTwitter,
  FaCalendarCheck,
  FaLightbulb,
  FaClock,
  FaUsers,
  FaCog,
  FaCheckCircle,
  FaArrowRight,
  FaExchangeAlt,
  FaRobot,
  FaSignal,
  FaCloud,
  FaCogs,
  FaCrown,
  FaCheck
} from 'react-icons/fa';
import tickRoundSvg from '../assets/tickround.svg';
import { Link } from 'react-router-dom';

const Enterprise = () => {
  const messages = [
    'Are you ready to take your Generative AI POCs into production?',
    'Do you want to compare OpenAI 01-preview vs 01-mini vs Anthropic AI agents?',
    'Building a GenAI-integrated application involves more than simply accessing LLMs, managing token usage, rotating access keys, and monitoring for PII.',
    'Running a GenAI project in production requires secure devops practices.',
    'We handle security, operational and Finops risks, so that you can concentrate on the business KPIs as your applications grow!'
  ];

  const categories = [
    { name: 'Customer Success', icon: <FaHeadset className="text-xl" /> },
    { name: 'Product Development', icon: <FaCode className="text-xl" /> },
    { name: 'Research', icon: <FaFlask className="text-xl" /> },
    { name: 'Marketing', icon: <FaChartLine className="text-xl" /> },
    { name: 'Finance', icon: <FaMoneyBillWave className="text-xl" /> },
    { name: 'Sales', icon: <FaHandshake className="text-xl" /> }
  ];

  const risks = [
    { 
      text: 'No RBAC, employees are using shared or personal API keys', 
      icon: <FaUserLock />,
      solution: {
        text: 'Centralized and role based access control for LLM access',
        icon: <FaUsersCog />
      }
    },
    { 
      text: 'Shadow AI running unchecked; sensitive data leaking through prompts', 
      icon: <FaExclamationTriangle />,
      solution: {
        text: 'Automatic sensitive data filtering',
        icon: <FaFilter />
      }
    },
    { 
      text: 'Runaway costs across departments', 
      icon: <FaDollarSign />,
      solution: {
        text: 'Hard cost controls at every level',
        icon: <FaChartBar />
      }
    },
    { 
      text: 'Lack of monitoring and audit trails', 
      icon: <FaChartBar />,
      solution: {
        text: 'Real-time monitoring and comprehensive audit logs',
        icon: <FaChartLine />
      }
    },
    { 
      text: 'Inconsistent performance across different LLM providers', 
      icon: <FaCode />,
      solution: {
        text: 'Complete data isolation behind your firewall',
        icon: <FaDatabase />
      }
    }
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const metrics = [
    { value: 65, suffix: '%', text: 'Average reduction in LLM spending' },
    { value: 85, suffix: '%', text: 'Decrease in unauthorized API usage' },
    { value: 89, suffix: '%', text: 'Less time spent on API management' },
    { value: 10, suffix: 'M+', text: 'Requests/minute throughput' },
    { value: 10, prefix: '<', suffix: 'Ms', text: 'Latency impact' },
    { value: 100.0, suffix: '%', text: 'Uptime' }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 4000);

    return () => clearInterval(timer);
  }, []);

  const handlePrevious = () => {
    setCurrentMessageIndex((prev) => (prev - 1 + messages.length) % messages.length);
  };

  const handleNext = () => {
    setCurrentMessageIndex((prev) => (prev + 1) % messages.length);
  };

  const MetricSlide = ({ value, suffix = '', prefix = '', text }) => {
    const { number } = useSpring({
      from: { number: 0 },
      to: { number: value },
      config: { duration: 2000 },
    });


    return (
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -100 }}
        transition={{ type: "spring", stiffness: 100, damping: 20 }}
        className="flex flex-col items-center justify-center text-center px-2 sm:px-4 w-full max-w-[280px] sm:max-w-none"
      >
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 sm:mb-4
                      bg-clip-text text-transparent 
                      bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800
                      animate-gradient">
          {prefix}
          <animated.span>
            {number.to(n => n.toFixed(n % 1 === 0 ? 0 : 1))}
          </animated.span>
          {suffix}
        </div>
        <p className="text-xs sm:text-sm md:text-base lg:text-lg text-black font-medium max-w-md">
          {text}
        </p>
      </motion.div>
    );
  };

  return (
    <>
      {/* Fixed Background Container - Will stay at top */}
      <div className="fixed top-0 left-0 right-0 w-full h-screen z-0">
        {/* Background SVG */}
        <div className="absolute inset-0">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            transition={{ duration: 1 }}
            src={backgroundSvg}
            alt=""
            className="w-full h-full object-cover"
            aria-hidden="true"
          />
        </div>

        {/* Left rect.svg */}
        <div className="absolute left-0 top-1/2 -translate-y-1/2 w-32 lg:w-48 h-auto">
          <motion.img
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 0.3, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            src={rectSvg}
            alt=""
            className="w-full h-auto"
            aria-hidden="true"
          />
        </div>

        {/* Top right ellipse.svg */}
        <div className="absolute right-0 top-0 w-48 lg:w-72 h-auto">
          <motion.img
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 0.3, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            src={ellipseSvg}
            alt=""
            className="w-full h-auto"
            aria-hidden="true"
          />
        </div>
      </div>

      {/* Main Content - Will scroll over fixed background */}
      <div className="relative w-full min-h-screen z-10">
        {/* Content Container */}
        <div className="relative w-full min-h-screen flex flex-col items-center justify-start px-4 sm:px-6 lg:px-8 pt-20">
          {/* Main Title */}
          <motion.h1 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 
                       font-black text-center 
                       bg-clip-text text-transparent 
                       bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                       animate-gradient tracking-tight 
                       drop-shadow-lg leading-tight 
                       pb-[0.1em]
                       max-w-6xl mx-auto
                       mb-16 sm:mb-20 lg:mb-24
                       pt-8"
          >
            Secure and Resilient Agents for Your GenAI Applications
          </motion.h1>

          {/* Split Section Container */}
          <div className="w-full max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row items-center justify-between gap-12 lg:gap-16">
              {/* Left Section - Animated Messages */}
              <div className="w-full lg:w-1/2">
                <motion.div 
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                  className="space-y-6 relative bg-gradient-to-r from-blue-600/10 via-purple-500/10 to-transparent 
                            p-4 sm:p-5 lg:p-6 rounded-lg border border-blue-500/20
                            hover:shadow-lg hover:shadow-blue-500/20
                            hover:border-blue-500/30"
                >
                  <AnimatePresence mode="wait">
                    <div className="relative">
                      {/* Opening Quote */}
                      <motion.span
                        key={`open-${currentMessageIndex}`}
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -10 }}
                        transition={{ duration: 0.3 }}
                        className="absolute -left-4 -top-4 text-3xl text-blue-800/40"
                      >
                        "
                      </motion.span>

                      {/* Message */}
                      <motion.p
                        key={currentMessageIndex}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                        className="text-sm sm:text-base lg:text-lg text-gray-800 font-medium leading-relaxed
                                  relative z-20 p-4 sm:p-6 text-center"
                      >
                        {messages[currentMessageIndex]}
                      </motion.p>

                      {/* Closing Quote */}
                      <motion.span
                        key={`close-${currentMessageIndex}`}
                        initial={{ opacity: 0, x: 10 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 10 }}
                        transition={{ duration: 0.3 }}
                        className="absolute -right-4 -bottom-4 text-3xl text-blue-800/40"
                      >
                        "
                      </motion.span>
                    </div>
                  </AnimatePresence>

                  {/* Enhanced Background Animation */}
                  <motion.div
                    className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-transparent"
                    animate={{
                      background: [
                        'linear-gradient(45deg, rgba(59,130,246,0.05) 0%, rgba(147,51,234,0.05) 50%, transparent 100%)',
                        'linear-gradient(45deg, rgba(147,51,234,0.05) 0%, rgba(59,130,246,0.05) 50%, transparent 100%)',
                      ],
                    }}
                    transition={{
                      duration: 3,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                    style={{ zIndex: 0 }}
                  />

                  {/* Floating Particles Effect */}
                  <motion.div
                    className="absolute inset-0 overflow-hidden"
                    style={{ zIndex: 1 }}
                  >
                    {[...Array(3)].map((_, i) => (
                      <motion.div
                        key={i}
                        className="absolute w-32 h-32 bg-blue-400/10 rounded-full"
                        animate={{
                          x: [
                            Math.random() * 100,
                            Math.random() * -100,
                            Math.random() * 100
                          ],
                          y: [
                            Math.random() * 100,
                            Math.random() * -100,
                            Math.random() * 100
                          ],
                          scale: [1, 1.2, 1],
                          opacity: [0.1, 0.2, 0.1],
                        }}
                        transition={{
                          duration: 8 + i * 2,
                          repeat: Infinity,
                          repeatType: "reverse",
                        }}
                      />
                    ))}
                  </motion.div>
                </motion.div>
              </div>

              {/* Right Section - Image */}
              <motion.div 
                className="w-full lg:w-1/2 flex justify-center lg:justify-end"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                <div className="relative">
                  {/* Image */}
                  <img
                    src={erpIcon}
                    alt="Enterprise Resource Planning"
                    className="relative z-10 w-80 sm:w-96 lg:w-[500px] h-auto rounded-lg
                               shadow-md"
                  />
                </div>
              </motion.div>
            </div>
          </div>

          {/* Fortune 500 Section */}
          <motion.div 
            className="w-full py-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1 }}
          >
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <motion.h2 
                className="text-2xl sm:text-3xl md:text-4xl text-center mb-6 sm:mb-8
                           bg-clip-text text-transparent 
                           bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                           animate-gradient tracking-tight 
                           drop-shadow-lg leading-tight 
                           pb-[0.1em]"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.2 }}
              >
                Why Customers Choose Trussed.ai?
              </motion.h2>

              <motion.p 
                className="text-base sm:text-lg text-black text-center max-w-4xl mx-auto
                           leading-relaxed mb-8
                           drop-shadow-[0_1px_1px_rgba(255,255,255,0.8)]"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.4 }}
              >
                Enterprises need robust oversight to safely scale Agentic and Generative AI enabled applications across a variety of areas including
              </motion.p>

              {/* Categories Grid */}
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4 sm:gap-6 lg:gap-8 max-w-4xl mx-auto">
                {categories.map((category, index) => (
                  <motion.div
                    key={index}
                    className="flex flex-col items-center justify-center p-4 sm:p-6
                               bg-gradient-to-br from-white/80 via-blue-50/50 to-indigo-50/50
                               backdrop-blur-sm rounded-xl
                               border border-blue-200/30"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1.6 + index * 0.1 }}
                  >
                    <div className="text-blue-800 mb-2 sm:mb-3 text-2xl sm:text-3xl">
                      {category.icon}
                    </div>
                    <h3 className="text-sm sm:text-base text-center text-gray-800 mb-1">
                      {category.name}
                    </h3>
                    <p className="text-xs sm:text-sm text-gray-600 text-center">
                      {category.description}
                    </p>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>

          {/* Current Risks and Mitigation with Trussed AI Section */}
          <motion.div 
            className="w-full py-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 2.0 }}
          >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <motion.h2 
                className="text-xl sm:text-2xl md:text-3xl text-center mb-6 sm:mb-8
                           bg-clip-text text-transparent 
                           bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                           animate-gradient tracking-tight 
                           drop-shadow-lg leading-tight 
                           pb-[0.1em]"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 2.2 }}
              >
                Current Risks and Mitigation with Trussed AI
              </motion.h2>

              <div className="overflow-x-auto">
                <motion.table 
                  className="min-w-full bg-white rounded-lg overflow-hidden shadow-lg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 2.4 }}
                >
                  <thead className="bg-gradient-to-r from-blue-600 to-indigo-600">
                    <tr>
                      <th className="px-4 py-3 border-b-2 border-gray-300 text-left text-sm sm:text-base font-semibold text-white">Current Risk</th>
                      <th className="px-4 py-3 border-b-2 border-gray-300 text-left text-sm sm:text-base font-semibold text-white">Mitigation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="hover:bg-gray-100 transition-colors">
                      <td className="px-4 py-3 border-b border-gray-200 text-sm sm:text-base text-red-600">No RBAC; employees using shared or personal API keys</td>
                      <td className="px-4 py-3 border-b border-gray-200 text-sm sm:text-base text-gray-700">Centralized, role-based access control (RBAC) for LLM access; enterprise-approved SSO and IAM policies.</td>
                    </tr>
                    <tr className="hover:bg-gray-100 transition-colors">
                      <td className="px-4 py-3 border-b border-gray-200 text-sm sm:text-base text-red-600">Shadow AI running unchecked; sensitive data leakage</td>
                      <td className="px-4 py-3 border-b border-gray-200 text-sm sm:text-base text-gray-700">Automated, contextual sensitive data filtering to prevent leaks through prompts.</td>
                    </tr>
                    <tr className="hover:bg-gray-100 transition-colors">
                      <td className="px-4 py-3 border-b border-gray-200 text-sm sm:text-base text-red-600">Runaway costs across departments</td>
                      <td className="px-4 py-3 border-b border-gray-200 text-sm sm:text-base text-gray-700">Granular budget management with spend visibility and control.</td>
                    </tr>
                    <tr className="hover:bg-gray-100 transition-colors">
                      <td className="px-4 py-3 border-b border-gray-200 text-sm sm:text-base text-red-600">No monitoring or audit trails for agentic flows</td>
                      <td className="px-4 py-3 border-b border-gray-200 text-sm sm:text-base text-gray-700">Comprehensive audit logs; integration with enterprise observability systems like Grafana, Prometheus, and OpenTelemetry.</td>
                    </tr>
                    <tr className="hover:bg-gray-100 transition-colors">
                      <td className="px-4 py-3 border-b border-gray-200 text-sm sm:text-base text-red-600">Diverse AI vendor ecosystems</td>
                      <td className="px-4 py-3 border-b border-gray-200 text-sm sm:text-base text-gray-700">Streamlined deployment across AI vendors; unified model lifecycle management via consistent APIs for OpenAI, Amazon Bedrock, Google Gemini, etc.</td>
                    </tr>
                  </tbody>
                </motion.table>
              </div>
            </div>
          </motion.div>

          {/* Key Metrics Section */}
          <motion.div 
            className="w-full py-12 mb-16"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 4.0 }}
          >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <motion.h2 
                className="text-2xl sm:text-3xl md:text-4xl font-black text-center mb-6 sm:mb-8
                           bg-clip-text text-transparent 
                           bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                           animate-gradient tracking-tight 
                           drop-shadow-lg leading-tight 
                           pb-[0.1em]"
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 400 }}
              >
                Key Metrics That Matter
              </motion.h2>

              {/* Metrics Slide */}
              <div className="relative max-w-4xl mx-auto mb-16 sm:mb-24">
                <div className="flex items-center justify-between px-2 sm:px-4">
                  <button
                    onClick={handlePrevious}
                    className="p-2 sm:p-3 rounded-full hover:bg-gray-100 transition-colors"
                  >
                    <FaChevronLeft className="text-lg sm:text-xl md:text-2xl text-blue-800" />
                  </button>
                  
                  <MetricSlide {...metrics[currentMessageIndex]} />
                  
                  <button
                    onClick={handleNext}
                    className="p-2 sm:p-3 rounded-full hover:bg-gray-100 transition-colors"
                  >
                    <FaChevronRight className="text-lg sm:text-xl md:text-2xl text-blue-800" />
                  </button>
                </div>
              </div>

              {/* Dots Indicator */}
              <div className="flex justify-center gap-2 mt-8">
                {metrics.map((_, index) => (
                  <motion.button
                    key={index}
                    className={`w-2 h-2 rounded-full ${
                      index === currentMessageIndex ? 'bg-blue-800' : 'bg-blue-800/30'
                    }`}
                    whileHover={{ scale: 1.2 }}
                    onClick={() => setCurrentMessageIndex(index)}
                  />
                ))}
              </div>
            </div>
          </motion.div>
          {/* Enterprise Packages Section */}
          <div className="w-full py-16 sm:py-20">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8">
                {/* Enterprise Elite Package Card */}
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="relative group h-full"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-indigo-600 
                                rounded-xl blur opacity-20 group-hover:opacity-30 
                                transition-opacity duration-300" />
                  <div className="relative bg-white rounded-xl shadow-md hover:shadow-lg h-full
                                transition-all duration-300 overflow-hidden border border-blue-100
                                flex flex-col">
                    {/* Header */}
                    <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4 text-center">
                      <h3 className="text-lg font-bold text-white mb-1">
                        Enterprise Elite Package
                      </h3>
                      <p className="text-blue-100 text-sm font-medium">
                        Built for demanding enterprises
                      </p>
                    </div>

                    {/* Content */}
                    <div className="p-4 flex-grow">
                      <div className="space-y-4">
                        {[
                          { icon: <FaUsers />, text: "Unlimited users" },
                          { icon: <FaCloud />, text: "Custom deployment options" },
                          { icon: <FaHeadset />, text: "24/7 premium support" },
                          { icon: <FaShieldAlt />, text: "Advanced security features" },
                          { icon: <FaPlug />, text: "Custom integrations" },
                          { icon: <FaUsersCog />, text: "Dedicated success team" }
                        ].map((feature, idx) => (
                          <motion.div
                            key={idx}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.1 * idx }}
                            className="flex items-center gap-3 group"
                          >
                            <div className="w-8 h-8 rounded-lg bg-blue-50 flex items-center justify-center
                                          group-hover:bg-blue-100 transition-colors duration-300
                                          flex-shrink-0">
                              <div className="text-sm text-blue-600">
                                {feature.icon}
                              </div>
                            </div>
                            <span className="text-sm sm:text-base text-gray-700 font-medium">{feature.text}</span>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.div>

                {/* 24/7 Enterprise Support Card */}
                <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="relative group h-full"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-purple-600 
                                rounded-xl blur opacity-20 group-hover:opacity-30 
                                transition-opacity duration-300" />
                  <div className="relative bg-white rounded-xl shadow-md hover:shadow-lg h-full
                                transition-all duration-300 overflow-hidden border border-indigo-100
                                flex flex-col">
                    {/* Header */}
                    <div className="bg-gradient-to-r from-indigo-600 to-purple-600 p-4 text-center">
                      <h3 className="text-lg sm:text-xl font-bold text-white mb-1">
                        24/7 Enterprise Support
                      </h3>
                      <p className="text-indigo-100 text-sm sm:text-base font-medium">
                        Round-the-clock expert assistance
                      </p>
                    </div>

                    {/* Content */}
                    <div className="p-4 sm:p-6 flex-grow">
                      <div className="space-y-6 sm:space-y-8">
                        {[
                          {
                            icon: <FaHeadset />,
                            title: "Direct line to our engineering team",
                            description: "Get immediate assistance from our expert engineers"
                          },
                          {
                            icon: <FaFileContract />,
                            title: "Custom SLAs available",
                            description: "Tailored service level agreements to meet your needs"
                          },
                          {
                            icon: <FaBolt />,
                            title: "Priority incident response",
                            description: "Fast-track resolution for critical issues"
                          }
                        ].map((feature, idx) => (
                          <motion.div
                            key={idx}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.1 * idx }}
                            className="space-y-2"
                          >
                            <div className="flex items-center gap-3">
                              <div className="w-8 h-8 sm:w-10 sm:h-10 rounded-lg bg-indigo-50 
                                            flex items-center justify-center
                                            group-hover:bg-indigo-100 transition-colors duration-300
                                            flex-shrink-0">
                                <div className="text-sm sm:text-base text-indigo-600">
                                  {feature.icon}
                                </div>
                              </div>
                              <h4 className="text-sm sm:text-base font-semibold text-gray-900">
                                {feature.title}
                              </h4>
                            </div>
                            <p className="text-sm sm:text-base text-gray-600 font-medium ml-11 sm:ml-13 
                                         leading-relaxed">
                              {feature.description}
                            </p>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>

          {/* Take Action Now Section */}
          <div className="w-full py-12 sm:py-16">
            <div className="max-w-5xl mx-auto px-4 sm:px-6">
              {/* Header Section */}
              <div className="text-center max-w-2xl mx-auto mb-8">
                <motion.h2 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-2xl sm:text-3xl font-bold mb-4
                             bg-clip-text text-transparent 
                             bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                             animate-gradient tracking-tight"
                >
                  Take Action Now
                </motion.h2>
                <motion.p
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 }}
                  className="text-sm sm:text-base text-gray-700 font-medium"
                >
                  Contact Us for a Deep Dive Session
                </motion.p>
              </div>

              {/* Cards Grid */}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mb-12">
                {/* Technical Deep Dive Card */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="relative group h-full"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-blue-600 
                                rounded-xl blur opacity-20 group-hover:opacity-30 
                                transition-opacity duration-300" />
                  <div className="relative bg-gradient-to-r from-blue-50 to-blue-100/50 
                                rounded-xl p-6 border border-blue-200 shadow-sm 
                                hover:shadow-md transition-all duration-300
                                h-full flex flex-col justify-between">
                    <div>
                      <h3 className="text-lg font-bold text-blue-900 text-center">Technical Deep Dive</h3>
                      <p className="text-sm text-blue-700 font-medium text-center mb-6">(45 mins)</p>
                      
                      <ul className="space-y-4">
                        {[
                          "Your Platform, DevOps & security teams",
                          "Our principal engineers",
                          "Live demo"
                        ].map((item, idx) => (
                          <li key={idx} className="flex items-center gap-3">
                            <img 
                              src={tickRoundSvg} 
                              alt="" 
                              className="w-5 h-5 flex-shrink-0"
                            />
                            <span className="text-sm text-blue-900 font-medium">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </motion.div>

                {/* Proof of Concept Card */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 }}
                  className="relative group h-full"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-indigo-600 
                                rounded-xl blur opacity-20 group-hover:opacity-30 
                                transition-opacity duration-300" />
                  <div className="relative bg-gradient-to-r from-indigo-50 to-indigo-100/50 
                                rounded-xl p-6 border border-indigo-200 shadow-sm 
                                hover:shadow-md transition-all duration-300
                                h-full flex flex-col justify-between">
                    <div>
                      <h3 className="text-lg font-bold text-indigo-900 text-center">Proof of Concept</h3>
                      <p className="text-sm text-indigo-700 font-medium text-center mb-6">(24 hours)</p>
                      
                      <ul className="space-y-4">
                        {[
                          "Deploy in your environment",
                          "Test with real traffic",
                          "Measure actual impact"
                        ].map((item, idx) => (
                          <li key={idx} className="flex items-center gap-3">
                            <img 
                              src={tickRoundSvg} 
                              alt="" 
                              className="w-5 h-5 flex-shrink-0"
                            />
                            <span className="text-sm text-indigo-900 font-medium">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </motion.div>

                {/* Enterprise Rollout Card */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className="relative group h-full"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-purple-500 to-purple-600 
                                rounded-xl blur opacity-20 group-hover:opacity-30 
                                transition-opacity duration-300" />
                  <div className="relative bg-gradient-to-r from-purple-50 to-purple-100/50 
                                rounded-xl p-6 border border-purple-200 shadow-sm 
                                hover:shadow-md transition-all duration-300
                                h-full flex flex-col justify-between">
                    <div>
                      <h3 className="text-lg font-bold text-purple-900 text-center">Enterprise Rollout</h3>
                      <p className="text-sm text-purple-700 font-medium text-center mb-6">&nbsp;</p>
                      
                      <ul className="space-y-4">
                        {[
                          "Full deployment support",
                          "Team training",
                          "24/7 dedicated support"
                        ].map((item, idx) => (
                          <li key={idx} className="flex items-center gap-3">
                            <img 
                              src={tickRoundSvg} 
                              alt="" 
                              className="w-5 h-5 flex-shrink-0"
                            />
                            <span className="text-sm text-purple-900 font-medium">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </motion.div>
              </div>

              {/* Bottom CTA Section */}
              <div className="text-center max-w-2xl mx-auto">
                <motion.p
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                  className="text-sm sm:text-base text-gray-700 font-medium mb-6"
                >
                  Don't wait for a security incident or cost overrun. Get control of your AI infrastructure now.
                </motion.p>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  <Link 
                    to="/book-demo"
                    className="inline-block bg-gradient-to-r from-blue-600 to-indigo-600 
                               text-white px-6 py-3 rounded-lg font-semibold text-sm sm:text-base
                               hover:from-blue-700 hover:to-indigo-700 
                               transition-all duration-300 transform hover:scale-105
                               shadow-md hover:shadow-lg active:scale-95"
                  >
                    Schedule Demo Now
                  </Link>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Add the gradient animation styles
const styles = `
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .animate-gradient {
    background-size: 200% 200%;
    animation: gradient 8s ease infinite;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    font-weight: 900;
    letter-spacing: -0.02em;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Enterprise;

import React from 'react';
import { motion } from 'framer-motion';
import { InlineWidget } from 'react-calendly';
import { FaRocket, FaTools, FaChartLine, FaShieldAlt } from 'react-icons/fa';
import backgroundSvg from '../assets/background.svg';
import rectSvg from '../assets/rect.svg';
import ellipseSvg from '../assets/ellipse.svg';

const demoFeatures = [
  {
    icon: <FaRocket className="text-blue-600 text-2xl" />,
    title: "Live Platform Demo",
    description: "See our AI Gateway in action with real-world use cases and implementations."
  },
  {
    icon: <FaTools className="text-indigo-600 text-2xl" />,
    title: "Technical Deep Dive",
    description: "Explore our architecture, integration capabilities, and developer tools."
  },
  {
    icon: <FaChartLine className="text-purple-600 text-2xl" />,
    title: "Performance Metrics",
    description: "Understanding the scalability, reliability, and efficiency improvements."
  },
  {
    icon: <FaShieldAlt className="text-blue-600 text-2xl" />,
    title: "Security Overview",
    description: "Learn about our enterprise-grade security features and compliance standards."
  }
];

const BookDemo = () => {
  return (
    <div className="relative w-full min-h-screen bg-white overflow-hidden">
      {/* Fixed Background SVG */}
      <div className="fixed inset-0 z-0">
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.7 }}
          transition={{ duration: 1 }}
          src={backgroundSvg}
          alt=""
          className="w-full h-full object-cover"
          aria-hidden="true"
        />
      </div>

      {/* Left rect.svg */}
      <div className="fixed left-0 top-1/2 -translate-y-1/2 w-32 lg:w-48 h-auto z-10">
        <motion.img
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 0.3, x: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          src={rectSvg}
          alt=""
          className="w-full h-auto"
          aria-hidden="true"
        />
      </div>

      {/* Top right ellipse.svg */}
      <div className="fixed right-0 top-0 w-48 lg:w-72 h-auto z-10">
        <motion.img
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 0.3, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          src={ellipseSvg}
          alt=""
          className="w-full h-auto"
          aria-hidden="true"
        />
      </div>

      {/* Content Container */}
      <div className="relative z-20 flex items-center justify-center min-h-screen py-12 px-4 sm:px-6 lg:px-8 pt-20 sm:pt-24 lg:pt-28">
        <div className="w-full max-w-7xl mx-auto">
          <motion.h1 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-2xl sm:text-3xl font-bold mb-12 text-center
                       bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                       bg-clip-text text-transparent 
                       animate-gradient tracking-tight 
                       drop-shadow-lg leading-tight"
          >
            Book a Demo with Trussed.ai
          </motion.h1>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
            {/* Left Column - Demo Features */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              className="flex flex-col h-full"
            >
              <div className="bg-white/50 backdrop-blur-sm rounded-xl p-6 border border-blue-500/20 shadow-lg h-[600px] overflow-y-auto">
                <h2 className="text-xl font-bold mb-6 text-gray-800">
                  What You'll See in the Demo
                </h2>
                <div className="space-y-6">
                  {demoFeatures.map((feature, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 + 0.5 }}
                      className="flex items-start space-x-4 p-4 rounded-lg
                               bg-gradient-to-r from-blue-50/50 to-purple-50/50
                               border border-blue-100/80
                               hover:shadow-md transition-all duration-300"
                    >
                      <div className="flex-shrink-0 p-2 bg-white rounded-lg shadow-sm">
                        {feature.icon}
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-800 mb-1">
                          {feature.title}
                        </h3>
                        <p className="text-sm text-gray-600">
                          {feature.description}
                        </p>
                      </div>
                    </motion.div>
                  ))}
                </div>

                {/* What to Expect - Moved inside main card */}
                <div className="mt-8 pt-8 border-t border-blue-200/20">
                  <h3 className="font-semibold text-gray-800 mb-4 text-center">
                    What to Expect
                  </h3>
                  <div className="max-w-md mx-auto">
                    <ul className="text-sm text-gray-600 space-y-3">
                      <li className="flex items-center space-x-2">
                        <span className="text-blue-500">•</span>
                        <span>30-minute personalized demo session</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span className="text-blue-500">•</span>
                        <span>Q&A with our technical experts</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span className="text-blue-500">•</span>
                        <span>Custom solutions for your use case</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span className="text-blue-500">•</span>
                        <span>Pricing and deployment options</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Right Column - Calendar */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              className="bg-white/50 backdrop-blur-sm rounded-xl shadow-lg p-4 border border-blue-500/20 h-[600px]"
            >
              <div className="calendly-inline-widget h-full">
                <InlineWidget 
                  url="https://calendly.com/ajay-dankar-trussed/30min"
                  styles={{
                    height: '100%',
                    width: '100%',
                  }}
                />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookDemo;

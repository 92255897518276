import React from 'react';
import { motion } from "framer-motion";
import { FaUniversity, FaBuilding, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import tickroundSvg from '../assets/tickround.svg';
import enterpriseImg from '../assets/action-enterprise.jpg';
import universityImg from '../assets/action-education.jpg';

const FeatureSection = ({ data, index, isReversed }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ duration: 0.8, delay: index * 0.2 }}
      className={`flex flex-col ${isReversed ? 'lg:flex-row-reverse' : 'lg:flex-row'} 
                gap-8 lg:gap-12 py-8 lg:py-16`}
    >
      {/* Image Section */}
      <motion.div 
        className="w-full lg:w-1/2"
        initial={{ opacity: 0, x: isReversed ? 50 : -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        <div className="relative rounded-2xl overflow-hidden group">
          <motion.img 
            src={data.image}
            alt={data.title}
            className="w-full h-[250px] lg:h-[400px] object-cover transform 
                     transition-all duration-700 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-gradient-to-t 
                        from-black/70 via-black/30 to-transparent" />
          
          {/* Overlay Content */}
          <div className="absolute bottom-0 left-0 right-0 p-6 lg:p-8 text-white">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="flex items-center gap-4 mb-4"
            >
              <div className="w-12 h-12 rounded-lg bg-white/10 backdrop-blur-sm
                           flex items-center justify-center">
                {data.icon}
              </div>
              <div>
                <h3 className="text-xl lg:text-2xl font-bold">{data.title}</h3>
                <p className="text-white/80">{data.subtitle}</p>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>

      {/* Content Section */}
      <div className="w-full lg:w-1/2 flex flex-col justify-center">
        <div className="space-y-6">
          {data.features.map((feature, i) => (
            <motion.div
              key={i}
              initial={{ opacity: 0, x: isReversed ? -20 : 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.5 + (i * 0.1) }}
              className="group"
            >
              <div className="flex gap-4 items-start">
                <motion.img
                  src={tickroundSvg}
                  alt=""
                  className="w-6 h-6 mt-1 flex-shrink-0"
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1 }}
                  transition={{ delay: 0.6 + (i * 0.1) }}
                />
                <div>
                  <h4 className="text-base lg:text-lg font-semibold mb-2
                               bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800
                               bg-clip-text text-transparent
                               hover:bg-gradient-to-r hover:from-blue-600 hover:via-indigo-600 hover:to-purple-600
                               transition-colors duration-300">
                    {feature.title}
                  </h4>
                  <p className="text-gray-600 text-sm lg:text-base leading-relaxed font-medium">
                    {feature.description}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* CTA Button */}
        <motion.div 
          className="mt-8"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
        >
          <Link 
            to={data.link} 
            onClick={() => window.scrollTo(0, 0)}
          >
            <motion.button
              className="group bg-gradient-to-r from-blue-600 to-indigo-600
                       text-white rounded-full px-8 py-3 
                       text-lg font-bold shadow-lg
                       flex items-center justify-center
                       hover:shadow-blue-500/30 hover:scale-[1.02]
                       transition-all duration-300"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Learn More
              <motion.span 
                className="ml-2 group-hover:translate-x-1 transition-transform"
                animate={{ x: [0, 5, 0] }}
                transition={{ duration: 1.5, repeat: Infinity }}
              >
                →
              </motion.span>
            </motion.button>
          </Link>
        </motion.div>
      </div>
    </motion.div>
  );
};

const TakeAction = () => {
  const sections = [
    {
      icon: <FaBuilding size={24} className="text-white" />,
      image: enterpriseImg,
      title: "Enterprise",
      subtitle: "Go-live with Confidence",
      features: [
        {
          title: "Resilient and Centralized Control",
          description: "Eliminate shadow AI usage, ensure LLM resilience, and manage access via APIs"
        },
        {
          title: "Optimize Costs and Improve Security",
          description: "Reduce TCO, ensure compliance, and safeguard corporate and user data against PII exposure, jailbreak, prompt injection and DDoS attacks"
        },
        {
          title: "Seamless Lifecycle Management",
          description: "Oversee users, applications, and models from onboarding to retirement"
        },
        {
          title: "Own Your Experience",
          description: "Deploy behind your firewall while maintaining full control over your UI/UX"
        }
      ],
      link: "/solutions/enterprise#top",
      isReversed: false
    },
    {
      icon: <FaUniversity size={24} className="text-white" />,
      image: universityImg,
      title: "Higher Education",
      subtitle: "Secure AI Access for Your Institution",
      features: [
        {
          title: "Manage LLM Access Keys",
          description: "Centralize control and streamline provisioning"
        },
        {
          title: "Control Student Access",
          description: "Set guardrails to ensure responsible and equitable usage"
        },
        {
          title: "Protect Research Projects",
          description: "Safeguard sensitive data and intellectual property"
        },
        {
          title: "Track Usage by Department",
          description: "Gain insights and accountability across your institution"
        }
      ],
      link: "/solutions/university#top",
      isReversed: true
    }
  ];

  return (
    <div className="relative py-16 lg:py-24 overflow-hidden">
      {/* Title Section */}
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <motion.h2
            className="text-2xl md:text-3xl lg:text-4xl font-bold 
                       bg-clip-text text-transparent 
                       bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                       animate-gradient tracking-tight drop-shadow-lg 
                       leading-tight
                       max-w-4xl mx-auto"
          >
            Take Action Today
          </motion.h2>
        </motion.div>

        {/* Features Sections */}
        <div className="space-y-16 lg:space-y-24">
          {sections.map((section, index) => (
            <FeatureSection 
              key={index}
              data={section}
              index={index}
              isReversed={section.isReversed}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TakeAction;
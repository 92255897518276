import React from 'react';
import { motion } from 'framer-motion';
import { 
  Container, 
  Typography, 
  Box, 
  Card, 
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  Button,
  Grid,
  ListItemText 
} from '@mui/material';
import { 
  CheckCircle, 
  ArrowForward, 
  Email,
  Business,
  Schedule,
  AttachMoney,
  Speed,
  Feedback,
  Lightbulb,
  Build,
  Computer 
} from '@mui/icons-material';
import styles from './DesignPartnerProgram.module.css';
import { useNavigate } from 'react-router-dom';
import partnerProgramImage from '../assets/partner-program.jpeg';

const DesignPartnerProgram = () => {
  const navigate = useNavigate();

  const fadeIn = {
    initial: { 
      opacity: 0, 
      y: 30,
      scale: 0.95
    },
    animate: { 
      opacity: 1, 
      y: 0,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: [0.6, -0.05, 0.01, 0.99]
      }
    }
  };

  const scrollVariants = {
    hidden: { 
      opacity: 0, 
      y: 50,
      scale: 0.97
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: [0.6, -0.05, 0.01, 0.99]
      }
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const cardHoverVariants = {
    hover: {
      scale: 1.02,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };

  const benefits = [
    {
      icon: <AttachMoney className={styles.featureIcon} />,
      title: "Significant Pricing Discounts",
      description: "Exclusive pricing discounts for the Trussed AI Controller."
    },
    {
      icon: <Business className={styles.featureIcon} />,
      title: "Expert Guidance",
      description: "Guidance on use cases and deploying Generative AI products in production environments."
    },
    {
      icon: <Speed className={styles.featureIcon} />,
      title: "Feature Enhancements",
      description: "Custom feature development and partnership success support."
    },
    {
      icon: <Feedback className={styles.featureIcon} />,
      title: "Direct Access",
      description: "Regular meetings and dedicated Slack channel for direct communication."
    }
  ];

  const programProvides = [
    {
      icon: <AttachMoney className={styles.featureIcon} />,
      title: "Pricing Benefits",
      description: "Significant pricing discounts for the Trussed AI Controller"
    },
    {
      icon: <Lightbulb className={styles.featureIcon} />,
      title: "Expert Support",
      description: "Expertise and guidance on use cases and deployment strategies"
    },
    {
      icon: <Build className={styles.featureIcon} />,
      title: "Custom Development",
      description: "Feature enhancements to ensure partnership success"
    }
  ];

  const requirements = [
    {
      icon: <Computer className={styles.featureIcon} />,
      title: "Implementation",
      description: "Run the Trussed AI controller in your environment"
    },
    {
      icon: <Feedback className={styles.featureIcon} />,
      title: "Feedback",
      description: "Provide regular product feedback to shape our roadmap"
    }
  ];

  const handleBookDemo = () => {
    navigate('/book-demo');
  };

  return (
    <div className={styles.designPartnerContainer}>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={styles.contentWrapper}
      >
        <Container maxWidth="lg">
          {/* Updated Hero Section */}
          <motion.div 
            className={styles.heroSection}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <div className={styles.heroContent}>
              <div className={styles.heroText}>
                <Typography className={styles.mainTitle}>
                  Trussed Design Partner Program
                </Typography>
                <Typography className={styles.subtitle}>
                  Shape the Future of AI Development
                </Typography>
              </div>
              <motion.div 
                className={styles.heroImage}
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.3 }}
              >
                <img 
                  src={partnerProgramImage} 
                  alt="Trussed Design Partner Program"
                  loading="eager"
                />
              </motion.div>
            </div>
          </motion.div>

          {/* Overview Section */}
          <motion.section 
            className={styles.section}
            variants={scrollVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <Typography className={styles.sectionTitle}>
              Program Overview
            </Typography>
            <Typography variant="body1">
              The Trussed Design Partner Program has been created for companies developing Generative AI applications...
            </Typography>
            
            <div className={styles.featureList}>
              {programProvides.map((feature, index) => (
                <motion.div 
                  className={styles.featureItem}
                  key={index}
                  variants={fadeIn}
                  whileHover={{ x: 10 }}
                >
                  {feature.icon}
                  <Typography className={styles.featureTitle}>
                    {feature.title}
                  </Typography>
                  <Typography>
                    {feature.description}
                  </Typography>
                </motion.div>
              ))}
            </div>
          </motion.section>

          {/* Benefits Section */}
          <motion.section 
            className={styles.section}
            variants={scrollVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <Typography className={styles.sectionTitle}>
              Partnership Benefits
            </Typography>
            
            <div className={styles.benefitsGrid}>
              {benefits.map((benefit, index) => (
                <motion.div 
                  className={styles.benefitItem}
                  key={index}
                  variants={fadeIn}
                  whileHover={{ y: -5 }}
                >
                  {benefit.icon}
                  <Typography variant="h6">
                    {benefit.title}
                  </Typography>
                  <Typography>
                    {benefit.description}
                  </Typography>
                </motion.div>
              ))}
            </div>
          </motion.section>

          {/* CTA Section */}
          <motion.section 
            className={styles.ctaSection}
            variants={scrollVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <Typography variant="h3" gutterBottom>
              Ready to Join?
            </Typography>
            <Typography variant="h6" gutterBottom>
              Take the first step towards transforming your AI development journey
            </Typography>
            <motion.div whileHover={{ scale: 1.05 }}>
              <Button 
                className={styles.ctaButton}
                endIcon={<Email />}
                onClick={handleBookDemo}
              >
                Contact Us to Join
              </Button>
            </motion.div>
          </motion.section>
        </Container>
      </motion.div>
    </div>
  );
};

export default DesignPartnerProgram;
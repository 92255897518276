// src/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5195f5', // Update to match the blue used throughout the app
    },
    secondary: {
      main: '#06d1d1', // Update to match the teal used throughout the app
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 500,
    },
    // Define other typography settings as needed
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
        },
      },
    },
    // Customize other components as needed
  },
});

export default theme;

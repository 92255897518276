import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FaShieldAlt, FaUniversity, FaChartLine, FaUserLock } from 'react-icons/fa';
import { SEO_DATA } from '../../constants/seoData';

const SEOPage = () => {
  const { metrics, features, testimonials } = SEO_DATA;
  
  const heroFeatures = [
    {
      icon: <FaShieldAlt className="w-6 h-6" />,
      title: "Complete Data Protection",
      description: "Deploy entirely behind your firewall with 100% data control"
    },
    {
      icon: <FaUniversity className="w-6 h-6" />,
      title: "University Ready",
      description: "Built specifically for higher education institutions"
    },
    {
      icon: <FaChartLine className="w-6 h-6" />,
      title: "Cost Control",
      description: "Cut LLM spending by up to 65% with smart optimization"
    },
    {
      icon: <FaUserLock className="w-6 h-6" />,
      title: "Enterprise Security",
      description: "Military-grade protection for your AI infrastructure"
    }
  ];

  return (
    <>
      <Helmet>
        <title>Trussed.ai | #1 Enterprise AI Gateway & LLM Security Platform</title>
        <meta name="description" content="Transform your AI security with Trussed.ai. Deploy behind your firewall, cut costs by 65%, and maintain complete data control." />
        <meta name="keywords" content="AI Gateway, LLM Security, Enterprise AI Security, University AI Platform" />
        
        <meta property="og:title" content="Trussed.ai - Secure AI Gateway for Enterprise & Education" />
        <meta property="og:description" content="Industry's first programmable AI Gateway and LLM Security platform." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://trussed.ai" />
        
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "Trussed.ai",
            "applicationCategory": "Enterprise AI Security Platform",
            "description": "Industry's first programmable AI Gateway and LLM Security platform",
            "operatingSystem": "Cloud, On-premises",
            "offers": {
              "@type": "Offer",
              "price": "Contact for Pricing"
            }
          })}
        </script>
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
        {/* Hero Section */}
        <motion.section 
          className="pt-20 pb-16 px-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="max-w-7xl mx-auto text-center">
            <motion.h1 
              className="text-5xl md:text-7xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-blue-500"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.8 }}
            >
              Enterprise AI Security Gateway
            </motion.h1>
            
            <motion.p 
              className="text-xl text-gray-300 mb-12 max-w-3xl mx-auto"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.8 }}
            >
              The industry's first programmable AI Gateway and LLM Security platform.
              Deploy in minutes, cut costs by 65%, and maintain complete data control.
            </motion.p>

            {/* Hero Features Grid */}
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
              {heroFeatures.map((feature, index) => (
                <motion.div
                  key={feature.title}
                  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 p-6 rounded-xl border border-gray-700/50"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.6 + index * 0.1, duration: 0.8 }}
                >
                  <div className="text-teal-400 mb-4">{feature.icon}</div>
                  <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-400 text-sm">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.section>

        {/* Main Features Section */}
        <section className="py-16 px-4 bg-gray-900/50">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-12">
              Why Fortune 500 Companies Choose Trussed.ai
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={feature.title}
                  className="bg-gradient-to-br from-gray-800/30 to-gray-900/30 p-6 rounded-xl border border-gray-700/30"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.2 + index * 0.1, duration: 0.8 }}
                >
                  <h3 className="text-xl font-semibold mb-4 text-teal-400">{feature.title}</h3>
                  <ul className="space-y-2">
                    {feature.items.map((item, i) => (
                      <li key={i} className="flex items-start gap-2">
                        <span className="text-teal-400 mt-1">•</span>
                        <span className="text-gray-300">{item}</span>
                      </li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Metrics Section */}
        <section className="py-16 px-4">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-12">
              Key Metrics That Matter
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {metrics.map((metric, index) => (
                <motion.div
                  key={metric.label}
                  className="text-center p-6 bg-gradient-to-br from-teal-900/20 to-teal-800/20 rounded-xl"
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: 0.2 + index * 0.1, duration: 0.8 }}
                >
                  <div className="text-4xl font-bold text-teal-400 mb-2">{metric.value}</div>
                  <div className="text-gray-300">{metric.label}</div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20 px-4">
          <div className="max-w-3xl mx-auto text-center">
            <motion.div
              className="bg-gradient-to-r from-teal-900/50 to-teal-800/30 p-8 rounded-2xl"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.8 }}
            >
              <h2 className="text-3xl font-bold mb-6">
                Transform Your AI Security Today
              </h2>
              <p className="text-gray-300 mb-8">
                Join leading institutions that trust Trussed to secure their AI future.
                Deploy in minutes and start seeing results immediately.
              </p>
              <motion.button
                className="px-8 py-4 bg-teal-500 text-white rounded-lg font-semibold hover:bg-teal-600 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Schedule a Demo
              </motion.button>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SEOPage;
// HeroSection.js
import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-router-dom';
import { FaCog, FaCode, FaShieldAlt } from "react-icons/fa";
import rectSvg from '../assets/rect.svg';
import ellipseSvg from '../assets/ellipse.svg';
import backgroundSvg from '../assets/background.svg';

// Define static content
const staticContent = {
  topTitle: "Industry's First",
  mainTitle: "Programmable Agentic AI Gateway",
  subtitle: "The Missing Layer in the Enterprise GenAI Stack!"
};

// Define content items for the animated section
const contentItems = [
  {
    paragraph: 'The GenAI Infrastructure Designed for Builders.'
  },
  {
    paragraph: 'Speed Up Your Path from POC to Production for GenAI projects'
  },
  {
    paragraph: 'Scale your GenAI Applications Without Increasing Your MLOps Teams!'
  },
  {
    paragraph: 'Keep Applications Running Even When LLMs Face Outages.'
  },
  {
    paragraph: 'Scale Smarter: Manage Growing GenAI Traffic Without Blowing the Budget.'
  },
  {
    paragraph: 'Empowering Developers to Build, Secure, and Optimize GenAI-Powered Applications.'
  },
  {
    paragraph: 'Agentic AI Made Practical: Add Intelligence, Stay in Control.'
  },
  {
    paragraph: 'See It All, Control It All: Centralized Visibility for Security, Operations, and FinOps for GenAI.'
  }
];

const AnimatedContent = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const intervalRef = useRef();

  useEffect(() => {
    if (!isPaused) {
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prev) => (prev + 1) % items.length);
      }, 8000);
    }
    return () => clearInterval(intervalRef.current);
  }, [items.length, isPaused]);

  return (
    <div className="w-full">
      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.6 }}
          className="relative"
        >
          <motion.div
            className="p-4 sm:p-5 lg:p-6 rounded-lg
                       transform hover:scale-[1.02] transition-all duration-300"
            onHoverStart={() => setIsPaused(true)}
            onHoverEnd={() => setIsPaused(false)}
          >
            <div className="flex items-center justify-center">
              <div className="flex-1 space-y-1 sm:space-y-2 relative">
                <TypeAnimation
                  key={currentIndex}
                  sequence={[
                    items[currentIndex].paragraph,
                    () => new Promise(resolve => setTimeout(resolve, 3000)),
                  ]}
                  wrapper="p"
                  speed={50}
                  className="text-gray-800
                             leading-tight
                             max-w-[90%] sm:max-w-3xl md:max-w-4xl mx-auto
                             tracking-tight
                             relative z-20
                             p-4 sm:p-6
                             text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl
                             transition-all duration-300
                             text-center
                             font-medium
                             whitespace-normal sm:whitespace-nowrap
                             overflow-hidden" 
                  cursor={true}
                />
              </div>
            </div>

            {/* Progress Bar */}
            <motion.div
              className="absolute bottom-0 left-0 h-[2px] bg-blue-500"
              initial={{ width: "0%" }}
              animate={{ width: "100%" }}
              transition={{ 
                duration: isPaused ? 0 : 8,
                ease: "linear"
              }}
              style={{ zIndex: 2 }}
            />
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const FeatureItem = ({ icon: Icon, title, description, index }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay: index * 0.15 }}
      className="h-full flex"
    >
      <motion.div
        whileHover={{ scale: 1.02 }}
        className="bg-gradient-to-r from-blue-400/20 via-blue-400/10 to-transparent 
                   p-6 rounded-lg border border-blue-500/15
                   transform hover:scale-[1.02] transition-all duration-300
                   hover:shadow-lg hover:shadow-blue-500/15
                   hover:border-blue-500/25
                   w-full flex flex-col"
      >
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            <motion.div
              className="w-12 h-12 rounded-lg bg-[#5195f5]
                         flex items-center justify-center shadow-lg"
              animate={{
                y: [-1, 1, -1],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            >
              <Icon size={24} className="text-white" />
            </motion.div>
          </div>

          <div className="flex-1 min-w-0">
            <motion.h3
              className="text-xl font-semibold mb-3
                         bg-gradient-to-r from-[#5195f5] to-[#5195f5]
                         bg-clip-text text-transparent 
                         leading-tight
                         drop-shadow-[0_2px_2px_rgba(0,0,0,0.5)]
                         tracking-tight
                         [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]"
            >
              {title}
            </motion.h3>

            <motion.p className="text-sm text-gray-600 leading-relaxed">
              {description}
            </motion.p>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const HeroSection = () => {
  return (
    <div className="relative w-full min-h-screen bg-white overflow-hidden">
      {/* Background SVG */}
      <div className="absolute inset-0 z-0">
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.7 }}
          transition={{ duration: 1 }}
          src={backgroundSvg}
          alt=""
          className="w-full h-full object-cover"
          aria-hidden="true"
        />
      </div>

      {/* Left rect.svg */}
      <div className="absolute left-0 top-1/2 -translate-y-1/2 w-32 lg:w-48 h-auto z-10">
        <motion.img
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 0.3, x: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          src={rectSvg}
          alt=""
          className="w-full h-auto"
          aria-hidden="true"
        />
      </div>

      {/* Top right ellipse.svg */}
      <div className="absolute right-0 top-0 w-48 lg:w-72 h-auto z-10">
        <motion.img
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 0.3, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          src={ellipseSvg}
          alt=""
          className="w-full h-auto"
          aria-hidden="true"
        />
      </div>

      {/* Hero Content Section */}
      <div className="relative z-20 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 sm:pt-28 md:pt-32 lg:pt-40">
        {/* Main Title Section */}
        <div className="text-center mb-4 sm:mb-6 lg:mb-8">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-black px-2 sm:px-4"
          >
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                           animate-gradient tracking-tight drop-shadow-lg 
                           inline-block whitespace-normal sm:whitespace-nowrap
                           leading-relaxed pb-1"
            >
              Industry's First Programmable Agentic AI Gateway
            </span>
          </motion.h1>
        </div>

        {/* Subtitle */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-center mb-6 sm:mb-8 lg:mb-12"
        >
          <motion.p 
            className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold
                     bg-gradient-to-r from-blue-600 via-purple-600 to-indigo-600
                     bg-clip-text text-transparent
                     animate-gradient-slow
                     tracking-tight
                     drop-shadow-sm
                     px-2 sm:px-4"
          >
            The Missing Layer in the Enterprise GenAI Stack!
          </motion.p>
        </motion.div>

        {/* Typewriter Section */}
        <div className="w-full max-w-[95%] sm:max-w-2xl md:max-w-3xl lg:max-w-4xl mx-auto mb-8 sm:mb-10 lg:mb-12">
          <AnimatedContent items={contentItems} />
        </div>

        {/* CTA Button */}
        <div className="flex justify-center w-full mb-8 sm:mb-12 lg:mb-16">
          <Link 
            to="/book-demo"
            className="inline-block px-2 sm:px-4"
          >
            <button className="group relative bg-gradient-to-r from-blue-400 to-indigo-600 
                             text-white rounded-full 
                             px-4 sm:px-6 lg:px-8 
                             py-2 sm:py-3 
                             text-sm sm:text-base lg:text-lg 
                             font-bold shadow-lg 
                             transition-all duration-300 
                             hover:scale-105 hover:shadow-blue-600/50 
                             overflow-hidden 
                             flex items-center justify-center">
              <span className="relative z-10">Book Demo</span>
              <motion.span 
                className="relative z-10 ml-2 inline-flex items-center"
                animate={{ x: [0, 5, 0] }}
                transition={{ duration: 1.5, repeat: Infinity }}
              >
                →
              </motion.span>
            </button>
          </Link>
        </div>

        {/* Description Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="w-screen relative left-[50%] right-[50%] mx-[-50vw] py-4 sm:py-6 lg:py-8"
        >
          <div className="w-[95%] sm:w-[90%] max-w-3xl sm:max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto">
            <motion.div 
              className="text-xs sm:text-sm md:text-base lg:text-lg
                       text-gray-600 text-center
                       leading-relaxed
                       tracking-tight
                       font-medium
                       px-2 sm:px-4"
            >
              <motion.p
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <span className="bg-gradient-to-r from-blue-600 via-purple-600 to-indigo-600 bg-clip-text text-transparent font-bold">
                  Trussed AI
                </span>
                {" "}enables teams building GenAI and Agentic AI platforms to centralize LLM access and streamline lifecycle management while ensuring resilience, controlling costs, and powering seamless operations from POC to production.
              </motion.p>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

const styles = `
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .animate-gradient {
    background-size: 200% 200%;
    animation: gradient 8s ease infinite;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    font-weight: 900;
    letter-spacing: -0.02em;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  @keyframes gradientSlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .animate-gradient-slow {
    background-size: 200% 200%;
    animation: gradientSlow 4s ease infinite;
  }
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default HeroSection;


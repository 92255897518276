export const SEO_DATA = {
  metrics: [
    { label: 'Cost Reduction', value: '65%' },
    { label: 'API Management Time', value: '89%' },
    { label: 'Compliance Rate', value: '100%' },
    { label: 'Data Breaches', value: 'Zero' },
    { label: 'Support Response', value: '24/7' },
    { label: 'User Capacity', value: '100k+' }
  ],
  features: [
    {
      title: 'Complete Data Protection',
      items: [
        'Deploy behind your firewall',
        'Keep data within your network',
        'Control sensitive information',
        'Shield records and research data'
      ]
    },
    {
      title: 'Enterprise Control',
      items: [
        'Centralized management',
        'Role-based access control',
        'Real-time monitoring',
        'Custom security policies'
      ]
    }
  ],
  testimonials: [
    {
      quote: "Trussed.ai has transformed our AI infrastructure.",
      author: "CTO, Fortune 100 Financial Institution"
    },
    {
      quote: "Unprecedented control and scalability in our AI projects.",
      author: "VP Engineering, Global Manufacturing Company"
    }
  ]
};
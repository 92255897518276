import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import routeTitles from '../routeTitles';

const TitleManager = () => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;

    // Handle dynamic routes like /blog/:postId
    if (path.startsWith('/blog/')) {
      const postId = path.split('/blog/')[1];
      document.title = `Trussed AI Blog Post - ${postId} | Trussed AI`;
      return;
    }

    // Set the document title based on the routeTitles mapping
    const title = routeTitles[path] || 'Trussed AI';
    document.title = title;
  }, [location]);

  return null; // This component doesn't render anything
};

export default TitleManager; 
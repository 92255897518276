import React from "react";
import { motion } from "framer-motion";
import { FaCog, FaCode, FaShieldAlt } from "react-icons/fa";
import genaiImg from '../assets/genai.jpg';
import developerImg from '../assets/application-developers.jpg';
import devopsImg from '../assets/devops.jpg';

const FeatureItem = ({ image, title, description, index, isReversed }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ duration: 0.8, delay: index * 0.2 }}
      className={`flex flex-col md:flex-row items-center gap-4 md:gap-6 py-6 md:py-8 
                ${isReversed ? 'md:flex-row-reverse' : ''}`}
    >
      {/* Image Section */}
      <motion.div 
        className="w-full md:w-2/5"
        initial={{ opacity: 0, x: isReversed ? 50 : -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true, margin: "-100px" }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        <motion.div 
          className="relative rounded-lg overflow-hidden shadow-md group"
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.3 }}
        >
          <img 
            src={image} 
            alt={title}
            className="w-full h-[150px] sm:h-[180px] md:h-[200px] object-cover 
                     transform transition-all duration-700
                     group-hover:scale-105"
          />
          <motion.div 
            className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
        </motion.div>
      </motion.div>

      {/* Content Section */}
      <motion.div 
        className="w-full md:w-3/5 space-y-4"
        initial={{ opacity: 0, x: isReversed ? -50 : 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true, margin: "-100px" }}
        transition={{ duration: 0.6, delay: 0.5 }}
      >
        <motion.div className="space-y-3">
          <motion.h3 
            className="text-lg md:text-xl font-bold
                       bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800
                       bg-clip-text text-transparent 
                       leading-tight tracking-tight animate-gradient"
            initial={{ opacity: 0, y: -10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            {title}
          </motion.h3>
          
          {/* Separator Line */}
          <motion.div 
            className="h-[2px] bg-gradient-to-r from-blue-500/60 to-purple-500/60 w-16"
            initial={{ width: 0, opacity: 0 }}
            whileInView={{ width: 64, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.7 }}
          />

          <motion.p 
            className="text-gray-700 text-sm md:text-base leading-relaxed pt-2 font-medium"
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ 
              duration: 0.6, 
              delay: 0.8,
              ease: "easeOut"
            }}
          >
            {description}
          </motion.p>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

const HighlightSection = () => {
  const features = [
    {
      image: genaiImg,
      title: "Built for Agentic AI and GenAI platform engineers",
      description: "Offer a seamless experience to teams in your company that have GenAI needs; from onboarding to go live and scale.",
      isReversed: false
    },
    {
      image: developerImg,
      title: "Loved by application developers",
      description: "Shorten time from POC to launch; focus on GenAI / Agentic AI business logic in your code; offload risk handling.",
      isReversed: true
    },
    {
      image: devopsImg,
      title: "Trusted by DevOps and infosec engineers",
      description: "Centralize policies for security and privacy, resilience, and FinOps; monitor, control, and remediate. Leverage SSO and Pipeline Automation.",
      isReversed: false
    }
  ];

  return (
    <div className="relative py-8 md:py-12 overflow-hidden">
      {/* Content Container */}
      <div className="relative z-10 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Title Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-8 md:mb-12"
        >
          <motion.h2
            className="text-lg md:text-xl lg:text-2xl font-bold 
                       bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800
                       bg-clip-text text-transparent
                       animate-gradient
                       tracking-tight drop-shadow-lg
                       leading-tight
                       max-w-3xl mx-auto
                       px-4"
          >
            Designed by AI/ML Developers, Infrastructure and DevOps engineers who have built, deployed and run GenAI-enabled Applications in production, at scale!
          </motion.h2>
        </motion.div>

        {/* Features Section */}
        <div className="space-y-8 md:space-y-12">
          {features.map((feature, index) => (
            <FeatureItem 
              key={index} 
              {...feature} 
              index={index} 
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HighlightSection;

const routeTitles = {
  '/': 'Trussed AI - Leading LLM Security Solutions',
  '/solutions': 'Trussed AI - Solutions for LLM Security',
  '/solutions/enterprise': 'Trussed AI - Enterprise LLM Security',
  '/solutions/university': 'Trussed AI - Academic LLM Security Solutions',
  '/company': 'Trussed AI - About Us',
  '/company/about': 'About Trussed AI - Pioneers in LLM Security',
  '/company/contact': 'Contact Trussed AI - LLM Security Experts',
  '/blog': 'Trussed AI Blog - Insights on LLM Security',
  '/blog/:postId': 'Trussed AI Blog Post',
  '/book-demo': 'Book a Demo with Trussed AI - Secure Your LLM',
  '/design-partner': 'Design Partner Program - Trussed AI',
  '/seo': 'Trussed AI SEO - Optimize Your LLM Security Presence',
  // Add more routes and titles as needed
};

export default routeTitles; 
import React from 'react';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import backgroundSvg from '../assets/background.svg';
import streamlinedImg from '../assets/streamlined-deployments.jpg';
import optimizeTcoImg from '../assets/optimize-tco.jpg';
import agenticaiImg from '../assets/agenticai.jpg';
import safeguardImg from '../assets/safeguard.jpg';

const FeatureSection = ({ title, content, image, index, isReversed }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 30 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8, delay: index * 0.2 }}
      className={`flex flex-col ${isReversed ? 'lg:flex-row-reverse' : 'lg:flex-row'} 
                items-center gap-8 lg:gap-16 mb-16 lg:mb-24 relative`}
    >
      {/* Image Section */}
      <motion.div 
        className="w-full lg:w-5/12"
        initial={{ opacity: 0, x: isReversed ? 50 : -50 }}
        animate={inView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        <div className="relative mx-auto w-[200px] h-[200px] lg:w-[300px] lg:h-[300px]">
          {/* Rotating Background Circle */}
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
            className="absolute inset-0 rounded-full 
                     bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-indigo-500/10"
          />
          
          {/* Image Container */}
          <div className="absolute inset-2 rounded-full overflow-hidden 
                       border-2 border-blue-500/20 group">
            <motion.img 
              src={image}
              alt={title}
              className="w-full h-full object-cover transform 
                       transition-all duration-700 group-hover:scale-110"
            />
            <div className="absolute inset-0 bg-gradient-to-t 
                         from-black/50 via-black/20 to-transparent" />
          </div>
        </div>
      </motion.div>

      {/* Content Section */}
      <div className="w-full lg:w-7/12 flex flex-col justify-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6, delay: 0.5 }}
          className="space-y-6"
        >
          <motion.h3
            className="text-lg lg:text-xl font-bold
                     bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800
                     bg-clip-text text-transparent
                     leading-tight tracking-tight"
          >
            {title}
          </motion.h3>

          {/* Animated Separator */}
          <motion.div 
            className="h-1 bg-gradient-to-r from-blue-500/60 to-purple-500/60 w-16"
            initial={{ width: 0, opacity: 0 }}
            animate={inView ? { width: 64, opacity: 1 } : {}}
            transition={{ duration: 0.8, delay: 0.6 }}
          />

          <motion.p
            initial={{ opacity: 0, y: 10 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, delay: 0.7 }}
            className="text-gray-600 leading-relaxed text-sm lg:text-base font-medium"
          >
            {content}
          </motion.p>
        </motion.div>
      </div>
    </motion.div>
  );
};

const RiskElimination = () => {
  const [contentRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const features = [
    {
      title: "Agentic AI",
      content: "Design, orchestrate, scale, optimize, and debug applications powered by autonomous agents.",
      image: agenticaiImg,
      isReversed: false
    },
    {
      title: "Streamlined Deployments Across AI Vendors",
      content: "Simplify integration, eliminate inconsistencies, and enhance debugging.",
      image: streamlinedImg,
      isReversed: true
    },
    {
      title: "Safeguard User and Corporate Data",
      content: "Ensure robust security in GenAI-powered applications for users and corporate data. Implement comprehensive protection measures for sensitive information.",
      image: safeguardImg,
      isReversed: false
    },
    {
      title: "Optimize TCO While Scaling Performance",
      content: "Mitigate soaring costs, address hallucinations, and resolve bottlenecks from reliable scaling. Maximize efficiency while maintaining optimal performance.",
      image: optimizeTcoImg,
      isReversed: true
    }
  ];

  return (
    <div className="relative py-16 lg:py-24 overflow-hidden bg-white">
      {/* Background SVG */}
      <div className="absolute inset-0 z-0">
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.7 }}
          transition={{ duration: 1 }}
          src={backgroundSvg}
          alt=""
          className="w-full h-full object-cover"
          aria-hidden="true"
        />
      </div>

      {/* Content Container */}
      <div className="relative z-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          ref={contentRef}
          className="text-center mb-16 lg:mb-24"
        >
          {/* Section Title */}
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8 }}
            className="text-2xl lg:text-3xl font-bold mb-6
                     bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800
                     bg-clip-text text-transparent animate-gradient"
          >
            The Production AI Challenge
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8 }}
            className="text-lg text-gray-700 max-w-3xl mx-auto leading-relaxed"
          >
            Enterprises face a widening gap between rapid AI prototyping and scalable production deployment. While creating proof-of-concept autonomous AI agents is getting simpler, moving them to production exposes critical challenges in:
          </motion.p>
        </motion.div>

        {/* Features Grid */}
        <div className="space-y-16 lg:space-y-24">
          {features.map((feature, index) => (
            <FeatureSection
              key={index}
              {...feature}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RiskElimination;

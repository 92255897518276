import React from "react";
import { FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import Internet2ProviderLogo from '../assets/cloud-score-card.png';
import NvidiaInceptionLogo from '../assets/nvidia.png';
import logoImage from '../assets/image.png';
import { motion } from "framer-motion";

const Footer = () => {
  return (
    <footer className="bg-white relative">
      {/* Footer Content */}
      <div className="container mx-auto px-4 py-12">
        {/* Footer Top */}
        <div className="flex flex-col lg:flex-row justify-between">
          {/* Company Information */}
          <div className="mb-8 lg:mb-0 lg:w-1/3">
            <div className="mb-4">
              <img 
                src={logoImage} 
                alt="Trussed.ai" 
                className="h-4 sm:h-5 md:h-6 lg:h-4 xl:h-5"
              />
            </div>
            <p className="text-gray-800 text-base sm:text-lg font-medium leading-relaxed">
              Trussed AI helps teams centralize LLM access, streamline lifecycle management, and ensure resilience, cost control, and smooth operations for GenAI and Agentic AI.
            </p>
          </div>

          {/* Navigation Links */}
          <div className="flex flex-col sm:flex-row lg:w-2/3 sm:pl-8 lg:pl-12">
            {/* Company Links */}
            <div className="mb-6 sm:mb-0 sm:mr-16 lg:mr-24">
              <h3 className="text-xl font-bold mb-4 
                            bg-clip-text text-transparent 
                            bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                            animate-gradient tracking-tight">
                Company
              </h3>
              <ul className="space-y-3">
                <li>
                  <Link 
                    to="/company/about" 
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-gray-600 hover:text-blue-700 transition-colors duration-300 font-medium"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/blog"
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-gray-600 hover:text-blue-700 transition-colors duration-300 font-medium"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/company/contact"
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-gray-600 hover:text-blue-700 transition-colors duration-300 font-medium"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>

            {/* Solutions Links */}
            <div>
              <h3 className="text-xl font-bold mb-4 
                            bg-clip-text text-transparent 
                            bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                            animate-gradient tracking-tight">
                Solutions
              </h3>
              <ul className="space-y-3">
                <li>
                  <Link 
                    to="/solutions/enterprise"
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-gray-600 hover:text-blue-700 transition-colors duration-300 font-medium"
                  >
                    Enterprise
                  </Link>
                </li>
                <li>
                  <Link 
                    to="/solutions/university"
                    onClick={() => window.scrollTo(0, 0)}
                    className="text-gray-600 hover:text-blue-700 transition-colors duration-300 font-medium"
                  >
                    University
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Social Media and Partner Logos */}
        <div className="mt-12 flex flex-col md:flex-row justify-between items-center">
          {/* Social Media Links */}
          <div className="flex justify-center space-x-6 mb-6 md:mb-0">
            <a href="https://twitter.com/trussedai" target="_blank" rel="noopener noreferrer" 
               className="text-blue-700 hover:text-blue-900 transition-colors duration-300">
              <FaTwitter size={20} />
            </a>
            <a href="https://www.linkedin.com/company/trussed" target="_blank" rel="noopener noreferrer" 
               className="text-blue-700 hover:text-blue-900 transition-colors duration-300">
              <FaLinkedinIn size={20} />
            </a>
          </div>

          {/* Partner Logos */}
          <div className="flex justify-center space-x-6">
            <a href="https://internet2.edu/cloud/internet2-net-plus-services/cloud-scorecard/cloud-scorecard-directory/" target="_blank" rel="noopener noreferrer" 
               className="bg-white/80 backdrop-blur-sm rounded-full p-3 shadow-lg hover:shadow-xl transition-shadow duration-300">
              <img src={Internet2ProviderLogo} alt="Internet2 Provider" className="w-12 h-12 object-contain" />
            </a>
            <a href="https://www.nvidia.com/en-us/deep-learning-ai/inception/" target="_blank" rel="noopener noreferrer" 
               className="bg-white/80 backdrop-blur-sm rounded-full p-3 shadow-lg hover:shadow-xl transition-shadow duration-300">
              <img src={NvidiaInceptionLogo} alt="NVIDIA Inception Program" className="w-12 h-12 object-contain" />
            </a>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-8 text-center text-sm text-gray-800">
          &copy; {new Date().getFullYear()} Trussed.ai. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;